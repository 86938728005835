import React, { useEffect } from 'react';
import ReactGA from "react-ga";
import styled from "styled-components";
import { gtag } from '../Cookies/GoogleConsentMode';

const CivicIntegration = () => {
  // Set default consent for Google Consent Mode
  // gtag('consent', 'default', {
  //   'ad_storage': 'denied',
  //   'ad_user_data': 'denied',
  //   'ad_personalization': 'denied',
  //   'analytics_storage': 'denied'
  // });
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }    
    var config = {
      onLoad: () => {
        setupEventHandlers();
      },
      apiKey: '26c9e39235a627ef72a598cb902e5ddd415e7792',
      product: 'PRO_MULTISITE',
      logConsent:true,
      thirdPartyToggle: true, 
      consentCookieExpiry:"90",
      encodeCookie:false,
      subDomains:true,
      notifyOnce:false,
      rejectButton:true,
      toggleType:"slider",
      closeStyle:"icon",
      settingsStyle:"link",
      initialState:"NOTIFY",
      layout:"SLIDEOUT",
      position:"LEFT",
      theme:"DARK",
      "optionalCookies": [{
        "name": "analytics",
        "label": "Performance Cookies",
        "description": "<p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>\r\n",
        "cookies": ['_gat', '_ga', 'G-YSME7QSYQZ'],
        "onAccept": function(){
          ReactGA.event({
            category: 'Cookie Prompt',
            action: 'Analytics Accepted',
          });
          gtag('consent', 'update', {
            'analytics_storage': 'granted'
        });
      },
        recommendedState:true,
        "onRevoke": function(){
          ReactGA.event({
            category: 'Cookie Prompt',
            action: 'Analytics Revoked',
          });
          gtag('consent', 'update', {
              'analytics_storage': 'denied'
          });
      }
    }, {
        "name": "marketing",
        "label": "Targeting Cookies",
        "description": "<p>They may be used by companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</p>\r\n",
        "cookies": ['_gat', '__ga', 'G-YSME7QSYQZ'],
        "onAccept": function(){
          ReactGA.event({
            category: 'Cookie Prompt',
            action: 'Marketing Accepted',
          });
          gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted'
          });
      },
      recommendedState:true,
      "onRevoke": function(){
        ReactGA.event({
          category: 'Cookie Prompt',
          action: 'Marketing Revoked',
        });
        gtag('consent', 'update', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied'
        });
    }
    },
    {
      "name": "thirdparty",
      "label": "Third Party Cookies",
      "description": 'Some pages include embeds from <a href="https://cdn.cms-twdigitalassets.com/content/dam/legal-twitter/site-assets/privacy-policy-2023-10-17/en/x-privacy-policy-2023-10-17.pdf" target="_blank"><b><u>Twitter</u></b></a>, <a href="https://www.gstatic.com/policies/privacy/pdf/20240304/a0z6wy2x/google_privacy_policy_en-GB_eu.pdf" target="_blank"><b><u>YouTube</b></u></a>, <a href="https://soundcloud.com/pages/privacy" target="_blank"><b><u>Soundcloud</b></u></a> and <a href="https://vimeo.com/privacy" target="_blank"><b><u>Vimeo</b></u></a>, which may use third party cookies.',
      "cookies": ['_gat', '__ga', 'G-YSME7QSYQZ'],
      "onAccept": function(){
        ReactGA.event({
          category: 'Cookie Prompt',
          action: 'Third Party Accepted',
        });
    },
    recommendedState:true,
      "onRevoke": function () {
        ReactGA.event({
          category: 'Cookie Prompt',
          action: 'Third Party Revoked',
        });
      }
  }
    ],
    
    excludedCountries:[],
      debug:false,
      setInnerHTML:true,
      mode:"GDPR",
      acceptBehaviour:"recommended",
      closeOnGlobalChange:true,
      notifyDismissButton:true,
      sameSiteCookie:true,
      sameSiteValue:"Strict",
      statement:{
        description: 'For more detailed information on the cookies we use, please check our',
        name : 'Privacy Policy',
        url: 'https://upreach.org.uk/privacy-notice-and-use-of-cookies/',
      },
      text:{
          title:"<p style='text-transform: none !important; font-size:18px !important; font-weight: bold;'>Cookie preference settings</p>",           
          intro:"<p>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>\r\n",
          acceptRecommended:"Accept All",
          acceptSettings:"I Accept",
          rejectSettings:"Reject all Non-Essential Cookies",
          thirdPartyTitle:"Warning: Some cookies require your attention.",
          thirdPartyDescription:"<p>Consent for the following cookies could not be automatically revoked. Please follow the link(s) below to opt out manually.</p>\r\n",
          on:"On",
          off:"Off",
          notifyTitle:"<p style='text-transform: none !important; font-size:16px !important; font-weight: bold; opacity: 1.8 !important;'>Your cookie choices for this website</p>",
          notifyDescription:"<p>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device or browser to enhance site navigation, analyse site usage, and assist in our marketing efforts. By clicking “Reject All Non-Essential Cookies”, you will accept cookies essential to make our site work, and you will reject all non-essential cookies. By clicking “X”, you will dismiss this banner and only essential cookies will be set on your device or browser. To customise your cookie choices for this website, click “Cookie Preferences”.</p>\r\n",
          accept:"Accept All Cookies",
          reject:"Reject all Non-Essential Cookies",
          settings:"Cookie Preferences",
          closeLabel:"Close",
          cornerButton:"Set cookie preferences.",
          landmark:"Cookie preferences.",
          showVendors:"Show vendors within this category",
          thirdPartyCookies:"This vendor may set third party cookies.",
          readMore:"Read more",
          necessaryTitle: "Strictly Necessary Cookies",
          necessaryDescription: "These cookies are necessary for our site to function and cannot be switched off. They are usually set in response to your actions, like setting your privacy preferences, creating an account, or logging in. You can set your browser to block these cookies, but some parts of the site will then not work."
      },
      branding:{
          fontSizeTitle:"large",
          fontSize:"small",
          fontColor:"#ffffff",
          fontSizeHeaders: "1.1em",
          backgroundColor:"#0e1a2e",
          toggleText:"#b164c0",
          toggleColor:"#b164c0",
          toggleBackground:"#ffffff",
          buttonIconWidth:"px",
          buttonIconHeight:"px",
          rejectText:null,
          rejectBackground:"",
          closeText:"",
          closeBackground:"",
          notifyFontColor:"",
          notifyBackgroundColor:""
      }
    };

    // Load the CookieControl script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js';
    // script.src = 'https://www.googletagmanager.com/gtag/js?id=GA1.1.779965715.1711017174';
    
    script.dataset.reactHelmet = 'true';
    script.onload = () => {
      window.CookieControl.load(config);
    };
    document.head.appendChild(script);
  }}, []);
  const setupEventHandlers = () => {
    // Assuming Civic Cookie Control provides a way to access the accept and reject events
    const acceptButton = document.getElementById('ccc-notify-accept');
    const rejectButton = document.getElementById('ccc-notify-reject');
    const settingsButton =  document.querySelector('.ccc-notify-link');

    if (acceptButton) {
      acceptButton.addEventListener('click', handleAccept);
    }

    // if (rejectButton) {
    //   rejectButton.addEventListener('click', handleReject);
    // }
  };

  const handleAccept = () => {
    ReactGA.event({
      category: 'Cookie Prompt',
      action: 'Accepted',
    });
  };

  // const handleReject = () => {
  //   ReactGA.event({
  //     category: 'Cookie Prompt',
  //     action: 'Rejected',
  //   });
  // };

  // const handleSettings = () => {
  //   ReactGA.event({
  //     category: 'Cookie Prompt',
  //     action: 'Settings',
  //   });
  // };
  
  return (
    <div>
    </div>
  );
};

export default CivicIntegration;