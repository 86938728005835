import React from 'react'
import styled from 'styled-components'
import sponserlogo1 from "../../images/pageImages/Homepage/14.png"
import sponserlogo2 from "../../images/pageImages/Homepage/15.png"

const StyledWrapper = styled.div`
.img-class{
    max-width:344px;
    min-width:300px;
    display:block;
    margin:auto;
    object-fit:cover;
    max-height:230px;
    @media (max-width:600px){
        max-width:100%;
    }
}

.sponser-link a{
    text-decoration:underline;
    color:black;
}

.header-cta{
    font-size:24px;
    font-family: ${props => props.theme["primaryFont"]} !important;
    text-decoration:underline;
    font-weight:800;
    &:hover{
        cursor:pointer
    }
    @media(max-width:600px){
      font-size:28px;
    }
  }
.img-class{
    transform: translateZ(0px);
}
`

export default function SponserSection({data}) {

    return(
        <StyledWrapper className="padding-left-8 padding-right-8 white-background">
            <div className="py-5">
                <h1 className="header-text">SPONSORS</h1>
                <div className="row py-3">
                    {data.Sponsor && data.Sponsor.map(item =>
                        <div className="col-lg-6 col-12 pb-4 d-flex align-self-center">
                            {item.Image !== null ? <img src={item.Image.publicURL} className="img-fluid img-class" alt="" />:<></>}
                        </div>  
                    )}
                </div>
                <div className="sponser-link">
                    <a href={data.href}><h2 className="header-text">{data.cta}</h2></a>
                </div>
            </div>
        </StyledWrapper>
    );
}