import React from "react";
import styled from "styled-components";
import twitterlogo from "../../images/Vector.svg";
import like from "../../images/like.svg";
import comment from "../../images/comment.svg";
import retweet from "../../images/retweet.svg";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  .description {
    font-size: 16px;
    line-height: 24px;
  }

  .href-card {
    text-decoration: underline;
    color: white;
  }

  .news-card {
    color: white;
  }

  .twiiter-card {
    color: black;
  }

  .name {
    font-size: 15px;
    font-weight: 700;
  }

  .user-name {
    font-size: 14px;
  }

  .pad-right {
    padding-right: 22% !important;
    padding-left: 8% !important;
    @media (max-width: 600px) {
      padding-right: 8% !important;
    }
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  .description a {
    text-decoration: underline;
    font-weight: 800;
    color: white;
    font-size: 24px;
  }

  .description strong {
    font-size: 24px;
    font-weight: 800;
  }

  .description p {
    font-size: 16px;
  }

  .pressImg {
    width: 100%;
    object-fit: cover;
  }
`;

export default function Press({ data, title, annotationBelowPressSection }) {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper className="py-4 padding-left-8 padding-right-8">
      <h1 className="header-text">{title}</h1>
      <div className="row">
        {data.map(item => (
          <div className="col-lg-4 news-card col-md-6 col-12 d-flex pb-3 pb-lg-2">
            <div
              className="col-12 px-4 py-4"
              style={{ backgroundColor: `${item.color}` }}
            >
              {item.image && (
                <img className="pressImg mb-3" src={mainUrl + item.image.url} />
              )}

              <div className="mb-2">{item.date}</div>
              <div className="description">
                <ReactMarkdown source={item.description} />
              </div>
            </div>
          </div>
        ))}

        {/* <div className="col-lg-4 twitter-card col-md-6 col-12 d-flex pb-3" >
                    <div className="col-12 padding-left-8 d-flex flex-column justify-content-between padding-right-8 pb-4 white-background">
                        <div>
                            <img src="" alt=""/>
                            <div className="row">
                                <div className="col-6">
                                    <div className="name">{item.name}</div>
                                    <div className="user-name">{item.username}</div>
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <img src={twitterlogo} alt="" />
                                </div>
                            </div>
                            <p className="description pt-3">{item.tweet}</p>
                        </div>
                        <div>
                            <div className="row">
                            <div className="col-6">
                                <img src={like} className="img-fluid pr-2" alt="" />
                                <img src={comment} alt="" className="img-fluid pr-2" />
                                <img src={retweet} alt="" className="img-fluid pr-2" />
                            </div>
                            <div className="col-6 d-flex justify-content-end">{item.date}</div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <p className="description px-3 pt-4">{annotationBelowPressSection}</p>
      </div>
    </StyledWrapper>
  );
}
