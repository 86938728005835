import React from "react"
import styled from "styled-components"
import { Timeline } from "react-twitter-widgets"
import { TwitterTimelineEmbed } from "react-twitter-embed"
import ReactMarkdown from 'react-markdown'

const StyledWrapper = styled.div`
  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }
  .twitterContainer{
   overflow-y:scroll !important;
   height:450px;
  }
  .twitterContainer::-webkit-scrollbar{
    display:none;
  }
`

const TwitterComponent = ({heading}) => {
  return (
    <StyledWrapper className="padding-left-8 padding-right-8 py-4">
      <div className="row">
        <div className="col-lg-6 header-text">
        <ReactMarkdown className="header-text">{heading}</ReactMarkdown>
        </div>
        <div className="col-lg-6 text-center twitterContainer">
          {/* <Timeline
                        dataSource={{
                            sourceType: 'up_Reach',
                            screenName: 'up_Reach'
                        }}
                        options={{ chrome: "noheader, nofooter",borderColor: "#fff",height:'400',width:"80%",tweetLimit:"5"}}
                        /> */}
          {/* <TwitterTimelineEmbed
          className="twitter__timeline"
            sourceType="up_Reach"
            screenName="up_Reach"
            options={{
              tweetLimit: "4",
              width:'80%',
              
            }}
            
          /> */}
          <TwitterTimelineEmbed 
          className="twitter__timeline"
            sourceType="up_Reach"
            screenName="up_Reach"
            options={{width:'80%',tweetLimit:'5'}}
            noHeader="true"
            noBorders="true"
            noFooter="true"
            
          />
        </div>
      </div>
    </StyledWrapper>
  )
}

export default TwitterComponent
