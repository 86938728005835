import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import styled from "styled-components";
import FlatButton from "../Reusables/FlatButton";
import carouselArrowRight from "../../images/carousel-arrow.svg";
import carouselArrowLeft from "../../images/carousel-left-arrow.svg";
import ReactMarkdown from "react-markdown";

const StyledWrapper = styled.div`
  .title-section {
    padding: 3% 8%;
    @media (max-width: 600px) {
      padding: 25px 8%;
      margin-top: 8px;
    }
  }
  .title {
    color: black;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 45px;
    text-transform: unset;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }
  .description-section {
    padding: 5% 3% 5% 8%;
    display: flex;
    max-height: 600px;
    flex-direction: column;
    justify-content: center;
    min-height: 162px;
    @media (max-width: 600px) {
      padding: 5% 2% 5% 5%;
    }
  }
  .image-section {
    max-height: 600px;
    min-height: 300px;
    @media (min-width: 600px) and (max-width: 991px) {
      height: 60vh;
    }
    @media (min-height: 992px) {
      height: 540px;
    }
  }
  .description {
    color: black;
    font-weight: lighter;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 40px;
    text-transform: unset;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
      min-height: 159px;
    }
    @media (min-width: 601px) and (max-width: 991px) {
      min-height: 264px;
    }
    @media (min-width: 992px) {
      min-height: 440px;
    }
  }
  .description a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold;
  }
  .description strong {
    font-weight: bold;
  }
  .flat-button {
    color: white;
  }
  .home-carousel-img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  .slider-slick {
    max-width: ${props => props.theme["percent-100"]};
  }
  // Carousel Css
  .slider-slick {
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
  .slick-arrow {
    width: 72px;
    height: 72px;
    display: none !important;
  }
  .slider-slick:hover .slick-arrow {
    display: block !important;
  }
  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }
  .slick-prev {
    left: 0;
    z-index: 1;
    background-color: white !important;
  }
  .slick-next {
    right: 0;
    background-color: white !important;
  }
  .slick-next:hover,
  .slick-prev:hover {
    background-color: white !important;
  }
  .slick-prev:before {
    content: url(${carouselArrowLeft});
    transform: rotate(180deg);
  }
  .slick-next:before {
    content: url(${carouselArrowRight});
  }
  .carousel-links {
    text-decoration: none;
  }
  a {
    color: black !important;
  }
`;

const Homecarousel = ({ carouselData }) => {
  var settings = {
    speed: 900,
    slidesToShow: 1,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid">
        <Slider {...settings} className="slider-slick">
          {carouselData.length > 0 &&
            React.Children.toArray(
              carouselData.map(item => (
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className="col-6 col-lg-6 col-xl-6 description-section padding-left-8"
                      style={{ backgroundColor: item.color }}
                    >
                      <h1 className="description d-flex align-items-start flex-column justify-content-center">
                        <ReactMarkdown
                          className="text-center"
                          source={item.Description}
                        />
                      </h1>
                    </div>
                    <div className="col-6 col-lg-6 col-xl-6 p-0 image-section">
                      {item.Image && (
                        <img
                          className="img-fluid home-carousel-img"
                          src={mainUrl + item.Image.url}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
            )}
        </Slider>
      </div>
    </StyledWrapper>
  );
};

export default Homecarousel;
