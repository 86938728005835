import React,{useState} from 'react'
import styled from 'styled-components'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import Button from '@material-ui/core/Button';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2"; 
import ReactGA from 'react-ga';

const StyledWrapper = styled.div`
.form-input{
    display:flex;
    flex-direction:column;
    padding-top:30px;
}

.btn-class{
    width:100%;
    background:#F2B842;
    font-size:20px;
    border:none;
    font-weight:800;
    padding:10px 0;
    margin-top:30px;
}

.label-class{
    font-size:20px;
    font-weight:800;
    line-height:24px;
}

.input-label{
    border:1px solid #F2B842;
    border-radius:5px;
    padding:10px;
}

.header-text{
    font-family: ${props => props.theme["primaryFont"]} !important;
}

.toast-color{
    background:#F2B842;
}

.progressClass{
    background: white;
}

.toastBodyClass{
    color: black;
}

`



export default function ContactForm(){
    const inputRef=React.useRef('')
    const { register, handleSubmit, watch, errors } = useForm();
    const [error,setError] = React.useState('')
    const [value,setValue]=React.useState('')
   
    const notifyToast = () =>{
        return toast("Your details have been submitted",{
            className: 'toast-color',
            progressClassName: 'progressClass',
            bodyClassName: 'toastBodyClass'
        });
    }
    
   
    const submit = (data) => {  
        fetch('https://formspree.io/f/xzbknoyl',{
            method:'POST',
            headers:{
                "Content-Type": "application/json",
            },
            body:JSON.stringify(data)
        }).then(function(response){
            if(response.status == 200){
                setValue('Thank you for contacting upReach about the Student Social Mobility Awards with your request. upReach will aim to respond within two working days.')
                Swal.fire({  
                    closeButtonHtml:'<span style="color:black">&times</span>',
                    width:'1000px',
                    className:'swal2',
                    background:'#F2B842',
                    html: '<h2 style="font-size:24px;font-weight:800;font-family:Nunito Sans;text-align:left;padding-bottom:10px;">Your form has been submitted</h2><h2 style="font-size:17px;font-weight:400;font-family:Nunito Sans;line-height:24px;text-align:left">Thank you for contacting upReach about the Student Social Mobility Awards with your request. upReach will aim to respond within two working days.</h2>',  
                    showCloseButton: true,
                    showConfirmButton:false,
                    
                    
                  });
                inputRef.current.reset();
            }else {
                return response.json().then(data=>setError(data.message));
              }
          }).catch(err => toast(err));
            ReactGA.event({
                category:"Homepage",
                action: "Contact Form Submission",
            });
    }

    return(
        <StyledWrapper className="padding-left-8 padding-right-8 col-lg-8 col-12 py-5">
            <h1 className="header-text">Contact Form</h1>
            <form ref={inputRef} onSubmit={handleSubmit(submit)} >
            <div className="form-input">
                <label htmlFor="Name" className="label-class">Name<span style={{color:'#E43D5A'}}>*</span></label>
                <input
                    id="Name"
                    className="input-label"
                    type="text"
                    placeholder="Name"
                    aria-invalid={errors.firstName ? "true" : "false"}
                    name="Name"
                    ref={register({ required: true })}
                />
            </div>
            <div className="form-input">
                <label htmlFor="Name" className="label-class">Organisation/University<span style={{color:'#E43D5A'}}>*</span></label>
                <input
                    id="university"
                    className="input-label"
                    type="text"
                    placeholder="University"
                    aria-invalid={errors.firstName ? "true" : "false"}
                    name="Organzation/University"
                    ref={register({ required: true })}
                />
            </div>
            
            <div className="form-input">
                <label htmlFor="Email" className="label-class">Email<span style={{color:'#E43D5A'}}>*</span></label>
                <input
                    id="Email"
                    className="input-label"
                    type="email"
                    placeholder="you@domian.com"
                    aria-invalid={errors.firstName ? "true" : "false"}
                    name="Email"
                    ref={register({ required: true })}
                />
            </div>
            <div className="form-input">
                <label htmlFor="details" className="label-class">Telephone number</label>
                <input
                    id="telephone"
                    className="input-label"
                    type="text"
                    placeholder="Telephone number"
                    aria-invalid={errors.firstName ? "true" : "false"}
                    name="Telephone number"
                    ref={register}
                />
            </div>
            <div className="form-input pb-4">
                <label htmlFor="details" className="label-class">Details of request<span style={{color:'#E43D5A'}}>*</span></label>
                <input
                    id="details"
                    className="input-label"
                    type="text"
                    placeholder="Details"
                    aria-invalid={errors.firstName ? "true" : "false"}
                    name="details"
                    ref={register({ required: true })}
                />
            </div>
            {error !== '' && <div>Form not submitted. {error}</div>}
             
            <button type="submit"  className="btn-class" disabled={value !== ""} >{value === "" ? "SUBMIT" : "SUBMITTED"}</button>
            <ToastContainer />
           
            </form>
        </StyledWrapper>
    );
}