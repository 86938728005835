import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import HeroImages from "../components/Reusables/HeroImages";
import SummaryBlock from "../components/Reusables/SummaryBlock";
import ImageCarousel from "../components/Homepage/ImageCarousel";
import Press from "../components/Homepage/PressSection";
import Testimonial from "../components/Reusables/Testimonials";
import SponserSection from "../components/Reusables/SponserSection";
import TwitterComponent from "../components/Homepage/TwitterComponent";
import StatisticBox from "../components/Homepage/StatisticBlock";
import Homecarousel from "../components/Reusables/Homecarousel";
import fsiAwardlogo from "../images/FSI-Award.jpg";
import charityGovernenceAward from "../images/Charity-Governance-Award.jpg";
import charityTimesAward from "../images/Charity-Times-Award.jpg";
import ReactMarkdown from "react-markdown";
import ContactForm from "../components/Homepage/ContactForm";
import HeroCarousel from "../components/Homepage/HeroCarousel";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import event from "../images/Event/pexels_videos_1578318 (1080p).mp4"
// import event from "../images/Event/stock-footage-vertical-video-animation-of-abstract-network-connection-structure-digital-background-with-dots-and.webm"
import { FaPlay } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import GoogleConsentModeScript from "../components/Cookies/GoogleConsentMode";
import CivicIntegration from "../components/Cookies/cookies";

const StyledWrapper = styled.div`
  .boxHeight {
    height: 450px;
    @media (max-width: 600px) {
      height: 350px;
    }
    @media (min-width: 768px) and (max-width: 882px) {
      height: 1000px;
    }
    @media (min-width: 883px) and (max-width: 1012px) {
      height: 850px;
    }
    @media (min-width: 1013px) and (max-width: 1300px) {
      height: 750px;
    }
    @media (min-width: 1300px) {
      height: 594px;
    }
  }
  .subtext strong {
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 40px;
    font-family: "Nunito Sans", sans-serif !important;
  }
  .subtext a {
    color: black;
    text-decoration: underline;
    font-size: 30px;
    font-weight: 800;
  }
  .top-div {
    background: black;
    color: ${props => props.theme["borderBottomColor"]};
  }
  .bottom-div {
    background: black;
    color: ${props => props.theme["borderBottomColor"]};
  }
  .top-div-heading {
    text-transform: uppercase;
    text-align: center;
    font-family: ${props => props.theme["primaryFont"]} !important;
    margin-bottom: 0px;
    @media (max-width: 600px) {
      line-height: 20px;
    }
  }
  .top-div-subheading {
    font-size: 28px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    padding-left: 3rem !important;
    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 24px;
      padding-left: 0 !important;
    }
  }
  .end-div {
    font-size: 48px;
    color: ${props => props.theme["borderBottomColor"]};
    text-decoration: underline;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
  .subtext-link {
    color: black;
    text-decoration: underline;
  }
  .about-images {
    height: 200px;
    contain: content;
    padding: 15px;
  }
  .banner-text strong {
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 40px;
    font-family: "Nunito Sans", sans-serif !important;
  }
  .banner-text p {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
  }
  .banner-text a {
    color: black;
    text-decoration: underline;
    font-size: 30px;
    font-weight: 800;
  }
  .Herobanner-heading p {
    margin-bottom: 0;
  }
  .Herobanner-heading a {
    color: black;
    text-decoration: underline;
  }
  .event-button {
  position: absolute;
  margin-top: -36px;
  background-color: #000000;
  backdrop-filter: blur(5px); 
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 1;
  color: #F2B842;
  padding: 12 24px;
  border: 2px solid #F2B842;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  padding-left:33px;
  padding-right:11px;
  padding-top:7px;
  padding-bottom:7px;
  font-family: 'Nunito Sans',sans-serif;
  @media (max-width: 900px) {
    margin-top: 0px;
    left: 52%;
    right: 93px;
}
  }
  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    z-index: 1;
  }
  
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    @media (max-width: 600px) {
    padding-bottom: 100%;
    }
  }
  
  .popup-content {
    position: relative;
    padding: 40px;
    border-radius: 4px;
    text-align: center;
    max-width: 800px; /* Adjust the maximum width as needed */
    max-height: 80vh; /* Adjust the maximum height as needed */
  }
  
  .popup-content video {
    // max-width: 105%;
    // max-height: 120%;
  }
  
  .close-button {
    position: absolute;
    top: 36px;
    right: 18px;
    width: 80px;
    height: 36px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  .close-button::before,
  .close-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 3px;
    background-color: #F2B842;
    transform-origin: center;
 }
  
  .close-button::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .close-button::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  .video {
    width: 100%;
    max-height: 400px;
  }
  .play-icon {
    position: absolute;
    left: 0;
    top: 49%;
    padding-bottom: 31px;
    padding-left: 4px;
    margin-left: 6px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 35px;
    height: 5px;
    border-radius: 50%;
    border: 2px solid #F2B842;
    @media (max-width: 600px) {
      position: absolute;
      left: 0;
      top: 50%;
      padding-bottom: 9px;
      padding-left: 1px;
      margin-left: 4px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 12px;
      height: 1px;
      border-radius: 50%;
      border: 1.5px solid #F2B842;
    }
  }

.bigger-button {
  font-size: 22px;
  padding-left: 38px;
  padding-right: 10px;
  @media (max-width: 600px) {
    font-size: 7px;
    padding-left: 13px;
    padding-right: 11px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.bigger-popup {
  width: 100%; /* Adjust the width as per your requirement */
  height: 100%; /* Adjust the height as per your requirement */
}

.popup-content div {
  @media (max-width: 600px) {
    width: 250px !important; 
    height: 200px !important; 
    min
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* Add any other styles for the .image-container */
}

`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IndexPage />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/homepage`);
};
const IndexPage = () => {
  const [newData, setNewData] = useState(null);
  const inputRef = useRef(null);
  const [onscroll, setOnscroll] = useState(false);
  const { data: pageData } = useQuery("indexData", fetchData);

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  useEffect(() => {
    if (newData && newData.showSummaryBlock === true) {
      inputRef.current.children[0].children[0].children[0].children[0].children[0].children[3].onclick = function (
        event
      ) {
        ReactGA.event({
          category: "Homepage",
          action: "Find Out More Button Click",
        });
      };
    }
  }, []);
  useEffect(() => {
    if (document.location.hash === "#contact-form") {
      setOnscroll(true);
    } else if (document.location.hash === "") {
      setOnscroll(false);
    } else {
      setOnscroll(false);
    }
  }, []);
  if (onscroll === true) {
    setTimeout(() => {
      document
        .querySelector("#contact-form")
        .scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} />}
        <div id="header" />
        {newData &&
          <div className="image-container">
          <HeroImages event='true' heroImage={newData.HeroImage}/>
          <button type="button" className="event-button bigger-button" name="button" onClick={togglePopup}> 
          <span className="play-icon">&#9658;</span>&nbsp; Watch the 2023 SSMA Film</button>
          </div>
        }
        {showPopup && (
        <div className="popup-container">
          <div className="popup-content">
            <ReactPlayer url={'https://www.youtube.com/watch?v=wKMF8t4e1ik'} controls={true} playing={true} className='react-player-custom'/>
            <button className="close-button" onClick={togglePopup}>
            </button>
        </div>
        </div>
      )}
        {/* <HeroImages donate = {true}  heroImage={event} /> */}
        {newData && newData.showHeroBanner && (
          <div className="padding-left-8 padding-right-8 py-3 home-hero-text white-background">
            <h2 className="top-div-heading Herobanner-heading">
              <ReactMarkdown source={newData.HeroBanner} />
            </h2>
            {/* <div className="top-div-subheading">{pageData.BannerSubHeading}</div> */}
          </div>
        )}

        {/* {pageData.showHeroImage && <HeroImages heroImage={pageData.HeroImage}/>} */}
        {newData &&
          newData.showHomeImageCarousel &&
          newData.HomePageCarousel.length > 0 && (
            <Homecarousel carouselData={newData.HomePageCarousel} />
          )}
        <div className="padding-left-8 padding-right-8 py-4 home-hero-text white-background">
          {newData && (
            <h2 className="top-div-heading ">{newData.BannerHeading}</h2>
          )}

          {/* <div className="top-div-subheading">{pageData.BannerSubHeading}</div> */}
        </div>
        {/* <HeroCarousel carouselData={stories} /> */}
        {newData && newData.showSummaryBlock && newData.SummaryBlock && (
          <div ref={inputRef}>
            <SummaryBlock summary={newData.SummaryBlock} />
          </div>
        )}
        {newData &&
          newData.showImageCarousel &&
          newData.ImageCarousel.length > 0 && (
            <div className="white-background py-5">
              <ImageCarousel data={newData.ImageCarousel} />
            </div>
          )}
        {newData && newData.showSummaryBlock2 && newData.SummaryBlock2 && (
          <SummaryBlock summary={newData.SummaryBlock2} />
        )}
        {newData && newData.showStatistics && newData.Statistics.length > 0 && (
          <StatisticBox data={newData.Statistics} />
        )}
        {newData && newData.ShowTestimonial && newData.Testimonial.length > 0 && (
          <div className="white-background">
            <Testimonial data={newData.Testimonial} />
          </div>
        )}
        {newData && newData.showSummaryBlock3 && newData.SummaryBlock3 && (
          <SummaryBlock summary={newData.SummaryBlock3} />
        )}
        {/* {pageData.ShowSponsor ? <SponserSection data={pageData.SponsorLink} /> : <></>} */}
        {newData && newData.ShowPress && newData.PressComponent.length > 0 && (
          <Press
            data={newData.PressComponent}
            title={newData.PressTitle}
            annotationBelowPressSection={newData.annotationBelowPressSection}
          />
        )}
        {/* {newData && <TwitterComponent heading={newData.TwitterText} />} */}
        <div className="white-background" id="contact-form">
          {newData && <ContactForm />}
        </div>
      </StyledWrapper>
      <CivicIntegration></CivicIntegration>
      <GoogleConsentModeScript></GoogleConsentModeScript>
    </Layout>
  );
};
